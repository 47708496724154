<template>
    <div class="app-dropdown-select" :class="{ selected, error, opened, disabled, readonly, multiline, inline: labelInline }">
        <div class="label" v-if="labelInline">{{ label }}<i v-if="required">*</i></div>

        <div class="error-message" v-if="error"><i></i><span>{{ error }}</span></div>

        <app-input
            v-if="predictiveSearch"

            v-model="search_query"

            :label="label"

            :error="error"

            :disabled="disabled"

            :required="required"

            :readonly="readonly"

            @onfocus="toggle"
        />
        <div v-else class="trigger" @click="toggle">
            <label @click="handleLabelClick" v-if="!labelInline && !error"><span>{{ label }}</span><i v-if="required">*</i></label>
            <span class="title">{{ title }}</span>
        </div>

        <div class="menu" ref="scroll">
            <div class="options">
                <div class="option" v-for="option in filtered_values" :key="option[keyValue]"
                    :class="{ active: option[keyValue] == value }"

                    @click="select(option)"
                >
                    {{ option[keyTitle] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appInput from '@/components/app-input'

import escapeStringRegexp from 'escape-string-regexp'

import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

export default {
    props: {
        value:       { required: true },
        options:     { type: [Array, Object], required: true },

        keyValue:    { type: String,  default: 'id'   },
        keyTitle:    { type: String,  default: 'name' },
        reference:   { type: String,  default: ''     },

        label:       { type: String,  default: ''     },
        labelInline: { type: Boolean, default: false  },

        disabled:    { type: Boolean, default: false  },
        readonly:    { type: Boolean, default: false  },

        required:    { type: Boolean, default: false  },
        multiline:   { type: Boolean, default: false  },
        error:       {                default: false  },

        predictiveSearch: { type: Boolean, default: false },
    },

    components: {
        appInput,
    },

    data() {
        return {
            opened: false,
            simple_bar: null,

            self: false,

            search_query: '',
            previous_search_query: '',
        }
    },
    
    mounted() {
        // this.initSimpleBar()
    },

    methods: {
        select(option) {
            this.self = true

            this.$emit('input', option[this.keyValue])
            this.$emit('select', option[this.keyValue])

            if (option[this.keyValue] !== this.value) {
                this.$emit('change', option[this.keyValue])
            }

            this.$emit('detailchange', {
                reference:     this.reference,
                label:         this.label,
                keyValue:      this.keyValue,
                keyTitle:      this.keyTitle,
                oldValue:      this.value,
                newValue:      option[this.keyValue],
            })

            this.close()
        },

        close() {
            this.search_query = this.title
            this.previous_search_query = this.search_query

            this.opened = false

            document.removeEventListener('click', this.handleClickOutside)
            this.$emit('close')
        },

        toggle() {
            if (!(this.disabled || this.readonly)) {
                this.opened = !this.opened

                if (this.opened) {
                    document.addEventListener('click', this.handleClickOutside)
                    this.$emit('open')
                } else {
                    this.close()

                    /*
                        document.removeEventListener('click', this.handleClickOutside)
                        this.$emit('close')
                    */
                }
            }
        },

        handleLabelClick(event) {
            if (this.selected) {
                event.stopPropagation()
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        /*
            initSimpleBar() {
                this.simple_bar = new SimpleBar(this.$refs.scroll, {
                    autoHide: false
                })
            },
        */
    },

    computed: {
        title() {
            let option = this.values
                ? this.values.find(item => item[this.keyValue] === this.value)
                : null

            return option ? option[this.keyTitle] : ''
        },

        selected() {
            return this.title && this.title.length > 0
        },

        values() {
            let values = []

            for (let i = 0, len = this.options_values.length; i < len; i++) {
                const value = this.options_values[i]

                values.push(typeof value == 'object'
                    ? value
                    : {
                        [this.keyValue]: value,
                        [this.keyTitle]: value,
                    }
                )
            }

            return values
        },

        options_values() {
            return this.options
                ? Array.isArray(this.options)
                    ? this.options
                    : Object.values(this.options)
                : []
        },

        filtered_values() {
            let filtered_values = []

            if (this.search_query.length > 0 && this.previous_search_query != this.search_query) {
                const pattern = new RegExp(escapeStringRegexp(this.search_query), 'gi')

                for (let i = 0, len = this.values.length; i < len; i++) {
                    if (pattern.test(this.values[i][this.keyTitle])) {
                        filtered_values.push(this.values[i])
                        pattern.lastIndex = 0
                    }
                }
            } else {
                filtered_values = this.values
            }

            return filtered_values
        },
    },

    watch: {
        value() {
            if (this.self) {
                this.self = false
            } else {
                // this.$nextTick(this.initSimpleBar)
            }
        },

        options() {
            // this.$nextTick(this.initSimpleBar)
        },

        title: {
            handler() {
                this.search_query = this.title
                this.previous_search_query = this.search_query
            },

            immediate: true,
        },
    },
}
</script>

<style lang="scss">
.app-dropdown-select {
    position: relative;
    width: 100%;
    font-size: 16px;

    $item-height: 32px;
    $item-line-height: 24px;
    $item-margin: 7px;

    $option-height: 48px;

    .app-input {
        input {
            padding-right: 40px;
        }

        &::after {
            position: absolute;
            top: 11px;
            right: 8px;

            display: block;

            width: 24px;
            height: 24px;

            transition: $transition-duration-primary;

            color: var(--color-icon-calm);

            @include icon($icon-chevron-bottom);
        }
    }

    .trigger {
        position: relative;
        display: flex;
        align-items: center;
        min-height: $option-height;
        padding: 11px 40px 11px 14px;
        border: solid 1px var(--color-input-border);
        border-radius: $border-radius-secondary;
        background: var(--color-input-bg);
        user-select: none;
        cursor: pointer;

        label {
            position: absolute;
            top: 50%;
            left: 12px;
            line-height: normal;
            transform: translateY(-50%);
            transition: $transition-duration-primary;

            span {
                position: relative;
            }

            i {
                color: var(--color-error);
                font-style: normal;
                position: relative;
                z-index: 1;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background-color: var(--color-input-bg);

                content: '';
            }
        }

        .title {
            line-height: normal;

            @include text-overflow();
        }

        &::after {
            position: absolute;
            top: calc(50% - 12px);
            right: 8px;

            display: block;

            width: 24px;
            height: 24px;

            transition: $transition-duration-primary;

            color: var(--color-icon-calm);

            @include icon($icon-chevron-bottom);
        }

        &:hover {
            border-color: var(--color-input-border-hover);
        }
    }

    .menu {
        position: absolute;
        top: calc(100% + 6px);
        left: 0;
        right: 0;
        z-index: 1;
        display: none;
        padding: 1px 0;
        max-height: 3 * $item-height + 4 * $item-margin;
        background-color: var(--color-autocomplete-bg);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);
        overflow: auto;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            width: 25px;
        }

        .simplebar-content {
            padding: 0 !important;
        }

        .simplebar-track {
            &.simplebar-vertical {
                top: 15px;
                right: 10px;
                bottom: 15px;
                width: 4px;
                background-color: var(--color-scrollbar-bg);
                border-radius: 2px;

                .simplebar-scrollbar {
                    &::before {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--color-scrollbar-thumb-bg);
                        border-radius: 2px;
                        opacity: 1;
                    }
                }
            }
        }

        .options {
            .option {
                height: $item-height;
                padding: #{ ($item-height - $item-line-height) / 2 } 24px;
                margin: $item-margin 0;
                line-height: $item-line-height;
                font-size: 18px;
                color: var(--color-autocomplete-option);
                cursor: pointer;

                @include text-overflow();

                &:hover {
                    background: var(--color-autocomplete-option-hover-bg);
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &.multiline {
        .trigger {
            .title {
                white-space: normal;
            }
        }

        .menu {
            .options {
                .option {
                    height: auto;
                    min-height: $item-height;
                    white-space: normal;
                }
            }
        }
    }

    .error-message {
        position: absolute;
        bottom: 100%;
        left: 0;
        display: flex;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-error);

        i {
            display: inline-block;

            width: 24px;
            height: 24px;

            @include icon-before($icon-attention);
        }

        span {
            @include text-overflow();
        }
    }

    &.selected,
    &.opened {
        .trigger {
            border-color: var(--color-input-border-active);

            label {
                top: 0;
                padding: 0 4px;
                font-size: 14px;
                color: var(--color-input-label-typing);

                i {
                    color: inherit;
                }

                &:before {
                    top: 45%;
                }
            }
        }
    }

    &.opened {
        .app-input {
            &::after {
                transform: rotate(180deg);
            }
        }

        .trigger {
            &::after {
                transform: rotate(180deg);
            }
        }

        .menu {
            display: block;
        }
    }

    &.error {
        .app-input {
            &::after {
                color: var(--color-error);
            }
        }

        .trigger {
            border: 1px solid var(--color-input-border-error);

            &::after {
                color: var(--color-error);
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: default;

        .app-input {
            opacity: 1;
        }
    }

    &.readonly {
        pointer-events: none;
        cursor: default;
    }

    &.inline {
        width: auto;
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .label {
            color: var(--color-input-placeholder);
            line-height: 20px;
        }

        .app-input {
            padding-right: 8px + 24px;

            &::after {
                right: 0;
            }
        }

        .trigger {
            border: none;
            border-radius: 0;
            padding-right: 8px + 24px;

            &::after {
                right: 0;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-dropdown-select {
        .trigger {
            padding-right: 32px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dropdown-select {
        font-size: 14px;

        .menu {
            .options {
                .option {
                    height: auto;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 8px 0;
                    padding: 4px 8px;
                }
            }
        }

        &.selected,
        &.opened {
            .trigger {
                label {
                    font-size: 12px;
                }
            }
        }

        &.inline {
            .label {
                font-size: 16px;
                line-height: 18px;
            }

            .trigger {
                padding: 11px 24px 11px 8px;

                .title {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
}
</style>
