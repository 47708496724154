<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <config-details
                    :uuid="asap_config_uuid"

                    ref="config-details"

                    @close-sidebar="closeSidebar"
                    @saved="getASAPConfigs"
                />
            </app-sidebar-details>
        </template>

        <div class="page-asap-configuration-list">
            <app-loader v-if="loading" fixed-on="desktop" />

            <app-error v-model="errors.show" :message="errors.message"></app-error>

            <app-table
                class="asap-configs-table"

                :sort-value="filter.sort"
                :sort-options="sort"
                @sort-change="onSortChange"

                :cols="cols"
                :rows="rows"

                :selected-row="asap_config_uuid"

                @edit-asap-config="onEditASAPConfig"
                @remove-asap-config="onRemoveASAPConfig"
            />

            <div class="actions">
                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />

                <button class="btn btn-primary btn-add-config" @click="onAddASAPConfig">Add new config</button>
            </div>

            <app-dialog-remove-asap-config
                v-if="dialogs.asap_config.remove.show"
                v-model="dialogs.asap_config.remove.show"

                :uuid="dialogs.asap_config.remove.uuid"

                @confirm="onDialogRemoveASAPConfigConfirm"
                @close="onDialogRemoveASAPConfigClose"
            ></app-dialog-remove-asap-config>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import configDetails from '@/views/asap/configuration/config-details'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appDialogRemoveAsapConfig from './components/app-dialog-remove-asap-config'

import errMessage from '@/helpers/errMessage'

import test_asap_configs from './test-asap-configs.json'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        configDetails,

        appLoader,
        appError,
        appTable,
        appPagination,

        appDialogRemoveAsapConfig,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'FromMSISDN',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'FromMSISDN',    title: 'From MSISDN'    },
                { field: 'ToMSISDN',      title: 'To MSISDN'      },
                { field: 'ProductCode',   title: 'Product Code'   },
                { field: 'ServiceNode',   title: 'Service Node'   },
                { field: 'SMSBillingId',  title: 'SMS Billing Id' },
                { field: 'ExceptionFlag', title: 'Exception Flag' },
                { field: 'OutdialPrefix', title: 'Outdial Prefix' },
            ],

            cols: [
                { key: 'FromMSISDN',    title: 'From MSISDN',    sort: { field: 'FromMSISDN'    }                  },
                { key: 'ToMSISDN',      title: 'To MSISDN',      sort: { field: 'ToMSISDN'      }                  },
                // { key: 'ProductCode',   title: 'Product Code',   sort: { field: 'ProductCode'   }, multiline: true },
                { key: 'ProductCode',   title: 'Product Code',   sort: { field: 'ProductCode'   }, truncated: true },
                // { key: 'Description',   title: 'Description',                                     multiline: true  },
                { key: 'Description',   title: 'Description',                                     truncated: true  },
                { key: 'ServiceNode',   title: 'Service Node',   sort: { field: 'ServiceNode'   }                  },
                { key: 'SMSBillingId',  title: 'SMS Billing Id', sort: { field: 'SMSBillingId'  }                  },
                { key: 'ExceptionFlag', title: 'Exception Flag', sort: { field: 'ExceptionFlag' }                  },
                { key: 'OutdialPrefix', title: 'Outdial Prefix', sort: { field: 'OutdialPrefix' }                  },

                { key: 'edit',   action: 'edit-asap-config',   icon: 'edit',   behavior: 'detached', },
                { key: 'remove', action: 'remove-asap-config', icon: 'remove', behavior: 'detached', },
            ],

            asap_configs: [],

            asap_config_uuid: null,

            loading: false,

            is_mobile_mode: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                asap_config: {
                    remove: {
                        show: false,

                        uuid: null,
                    },
                },
            },

            sidebar: {
                create: false,
                edit: false,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getASAPConfigs()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getASAPConfigs()
        },

        onPageChange(page) {
            this.getASAPConfigs(page)
        },

        /*
            getASAPConfigs(page) {
                const delay_in_ms = 1000

                this.loading = true

                setTimeout(() => {
                    const list = []

                    const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                    const total = test_asap_configs.length
                    const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                    for (let i = start; i < end; i++) {
                        list.push(test_asap_configs[i])
                    }

                    this.asap_configs = list

                    this.pagination.page = page || this.pagination.page
                    this.pagination.total = total

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { env: this.env, page: this.pagination.page },
                            replace: true,
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                }, delay_in_ms)
            },
        */

        getASAPConfigs(page) {
            this.loading = true

            const params = {
                'SPID':                     this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
                'VFEnvironment':            this.vfenvironment_options[this.env],
            }

            this.$store.dispatch('api_asap/GetASAPConfigsPaginated', params)
                .then(response => {
                    this.asap_configs = response.ASAPConfigs

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { env: this.env, page: this.pagination.page },
                            replace: true,
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.asap_configs = []

                    this.loading = false
                })
        },

        onAddASAPConfig() {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'asap-configuration-create', params: { env: this.env } })
            } else {
                this.asap_config_uuid = null

                this.sidebar.create = true
                this.sidebar.edit   = false

                this.openSidebar()
            }
        },

        onEditASAPConfig(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'asap-configuration-edit', params: { env: this.env, uuid: row.uuid } })
            } else {
                this.asap_config_uuid = row.uuid

                this.sidebar.create = false
                this.sidebar.edit   = true

                this.openSidebar()
            }
        },

        onRemoveASAPConfig(row) {
            this.dialogs.asap_config.remove.show = true
            this.dialogs.asap_config.remove.uuid = row.uuid
        },

        onDialogRemoveASAPConfigConfirm() {
            this.dialogs.asap_config.remove.show = false
            this.dialogs.asap_config.remove.uuid = null

            this.getASAPConfigs()
        },

        onDialogRemoveASAPConfigClose() {
            this.dialogs.asap_config.remove.show = false
            this.dialogs.asap_config.remove.uuid = null
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.asap_config_uuid = null

            this.$emit('close-sidebar')

            this.$refs['config-details'].reset()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
            'vfenvironment_options',
        ]),

        rows() {
            return this.asap_configs.map(config => ({
                uuid: config.UUID,

                FromMSISDN: config.FromMSISDN ? config.FromMSISDN : '?',
                ToMSISDN: config.ToMSISDN ? config.ToMSISDN : '?',
                ProductCode: config.ProductCode ? config.ProductCode : '?',
                Description: config.Description ? config.Description : '?',
                ServiceNode: config.ServiceNode ? config.ServiceNode : '?',
                SMSBillingId: config.SMSBillingId ? config.SMSBillingId : '?',
                ExceptionFlag: typeof config.ExceptionFlag == 'boolean'
                    ? config.ExceptionFlag ? 'Yes' : 'No'
                    : '?',
                OutdialPrefix: config.OutdialPrefix ? config.OutdialPrefix : '?',
            }))
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.create: {
                    title = 'Add new config'
                } break

                case this.sidebar.edit: {
                    title = 'Edit config'
                } break
            }

            return title
        },

        env() {
            return this.$route.params.env
        },
    },

    watch: {
        $route(to, from) {
            if (to.params.env != from.params.env) {
                this.pagination.page = 1
                this.pagination.total = 0

                this.getASAPConfigs()
            }
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-asap-configuration-list {
    .app-error {
        margin-bottom: 24px;
    }

    .asap-configs-table {
        @include table-cols-width((150px, 150px, 250px, 250px, 150px, 150px, 150px, 150px, 48px, 48px), true, 2);
    }

    & > .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .app-pagination,
        .btn-add-config {
            margin-top: 30px;
        }

        .app-pagination {
            flex-grow: 1;

            margin-right: 16px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-asap-configuration-list {
        .asap-configs-table {
            @include table-cols-width((130px, 130px, 200px, 200px, 130px, 130px, 130px, 130px, 48px, 48px), true, 2);
        }

        & > .actions {
            flex-direction: column;

            .app-pagination {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-asap-configuration-list {
        .asap-configs-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        & > .actions {
            .app-pagination,
            .btn-add-config {
                margin-top: 15px;
            }
        }
    }
}
</style>