<template>
    <div id="page-config-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to ASAP configuration</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h2 class="heading">Config details</h2>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-textarea
                                    v-model="asap_config.Description"

                                    label="Description"

                                    :error="errors.fields.Description"

                                    @change="errors.fields.Description = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="asap_config.FromMSISDN"

                                    label="From MSISDN"
                                    :required="true"

                                    :mask="phone_number_mask"

                                    :error="errors.fields.FromMSISDN"
                                    :disabled="is_edit_mode"

                                    @change="errors.fields.FromMSISDN = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="asap_config.ToMSISDN"

                                    label="To MSISDN"
                                    :required="true"

                                    :mask="phone_number_mask"

                                    :error="errors.fields.ToMSISDN"
                                    :disabled="is_edit_mode"

                                    @change="errors.fields.ToMSISDN = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="asap_config.ProductCode"

                                    label="Product code"
                                    :required="true"

                                    :error="errors.fields.ProductCode"

                                    @change="errors.fields.ProductCode = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-dropdown-select
                                    v-model="asap_config.ServiceNode"
                                    :options="service_node_options"

                                    key-value="value"
                                    key-title="title"

                                    label="Service Node"
                                    :required="true"

                                    :error="errors.fields.ServiceNode"

                                    @change="errors.fields.ServiceNode = null"
                                />

                                <app-dropdown-select
                                    v-model="asap_config.SMSBillingId"
                                    :options="sms_billing_id_options"

                                    key-value="value"
                                    key-title="title"

                                    label="SMS Billing Id"
                                    :required="true"

                                    :error="errors.fields.SMSBillingId"

                                    @change="errors.fields.SMSBillingId = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-radio-buttons
                                    v-model="asap_config.ExceptionFlag"
                                    :items="exception_flag_options"

                                    :inline="true"

                                    label="Exception Flag:"
                                />
                            </div>

                            <div class="form-controls">
                                <app-dropdown-select
                                    v-model="asap_config.OutdialPrefix"
                                    :options="outdial_prefix_options"

                                    key-value="value"
                                    key-title="title"

                                    label="Outdial Prefix"
                                    :required="true"

                                    :error="errors.fields.OutdialPrefix"

                                    @change="errors.fields.OutdialPrefix = null"
                                />
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.show" :message="errors.message" />

                    <button class="btn btn-primary btn-save" v-if="is_edit_mode" :disabled="!is_changed" @click="save">Save</button>
                    <button class="btn btn-primary btn-save" v-else              :disabled="!is_changed" @click="save">Add</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

import ASAPConfigValidator from '@/validators/asap-config-validator'

import test_asap_config from './test-asap-config.json'

const COUNTRY_CODE = '64'

const EMPTY_ASAP_CONFIG = {
    Description: '',
    FromMSISDN: null,
    ToMSISDN: null,
    ProductCode: '',
    ServiceNode: '',
    SMSBillingId: '',
    ExceptionFlag: false,
    OutdialPrefix: '',
}

const CONFIG = {
    'SIT-A': {
        SERVICE_NODE: ['0', '16'],
        SMS_BILLING_ID: ['15'],
    },

    'SIT-B': {
        SERVICE_NODE: ['0', '16'],
        SMS_BILLING_ID: ['15'],
    },

    'SIT-C': {
        SERVICE_NODE: ['0', '16'],
        SMS_BILLING_ID: ['15'],
    },

    'PREPROD': {
        SERVICE_NODE: ['0', '18', '19', '22'],
        SMS_BILLING_ID: ['15', '16'],
    },

    'DEV': {
        SERVICE_NODE: ['0', '16'],
        SMS_BILLING_ID: ['15'],
    },
}

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appRadioButtons,
        appDropdownSelect,
    },

    data() {
        return {
            phone_number_mask: {
                mask: `${COUNTRY_CODE}999999999`,
                jitMasking: false,
            },

            asap_config: JSON.parse(JSON.stringify(EMPTY_ASAP_CONFIG)),
            original_asap_config: JSON.parse(JSON.stringify(EMPTY_ASAP_CONFIG)),

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.is_edit_mode) {
                this.getASAPConfig()
            }
        },

        /*
            getASAPConfig() {
                this.loading = true

                const delay_in_ms = 1000

                setTimeout(() => {
                    this.original_asap_config = JSON.parse(JSON.stringify(test_asap_config))
                    this.asap_config          = JSON.parse(JSON.stringify(test_asap_config))

                    this.loading = false
                }, delay_in_ms)
            },
        */

        getASAPConfig() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUID: this.asap_config_uuid,
                VFEnvironment: this.vfenvironment_options[this.env],
            }

            this.$store.dispatch('api_asap/GetASAPConfigByUUID', params)
                .then(response => {
                    this.original_asap_config = JSON.parse(JSON.stringify(response))
                    this.asap_config          = JSON.parse(JSON.stringify(response))

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        validation() {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',

                fields: {},
            }

            const fields = {
                FromMSISDN:    { rule: 'PhoneNumber',   message: 'Please, enter valid MSISDN'       },
                ToMSISDN:      { rule: 'PhoneNumber',   message: 'Please, enter valid MSISDN'       },
                ProductCode:   { rule: 'ProductCode',   message: 'Please, enter valid product code' },
                ServiceNode:   { rule: 'ServiceNode',   message: 'Please, select service node'      },
                SMSBillingId:  { rule: 'SMSBillingId',  message: 'Please, select sms billing id'    },
                OutdialPrefix: { rule: 'OutdialPrefix', message: 'Please, select outdial prefix'    },
            }

            for (const key in fields) {
                if (ASAPConfigValidator.isRuleExists(fields[key].rule)) {
                    if (ASAPConfigValidator.isInvalid(fields[key].rule, this.asap_config[key], fields[key].message)) {
                        this.errors.fields[key] = ASAPConfigValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.errors.fields.FromMSISDN && !this.errors.fields.ToMSISDN) {
                if (+this.asap_config.FromMSISDN >= +this.asap_config.ToMSISDN) {
                    this.errors.show = true
                    this.errors.message = '"To MSISDN" must be greater than "From MSISDN"'

                    is_valid = false
                }
            }

            return is_valid
        },

        /*
            save() {
                if (this.validation()) {
                    this.loading = true

                    const delay_in_ms = 1000

                    setTimeout(() => {
                        this.errors = {
                            show: false,
                            message: '',

                            fields: {},
                        }

                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    }, delay_in_ms)
                }
            },
        */

        save() {
            if (this.validation()) {
                this.loading = true

                const action = this.is_edit_mode ? 'UpdateASAPConfig' : 'AddASAPConfig'

                const message = this.is_edit_mode ? 'Changes was successfully applied' : 'New config was successfully created'

                const params = {
                    ...this.asap_config,

                    SPID: this.current_spid,

                    FromMSISDN: +this.asap_config.FromMSISDN,
                    ToMSISDN: +this.asap_config.ToMSISDN,

                    VFEnvironment: this.vfenvironment_options[this.env],
                }

                this.$store.dispatch(`api_asap/${ action }`, params)
                    .then(response => {
                        this.original_asap_config = JSON.parse(JSON.stringify(response))
                        this.asap_config          = JSON.parse(JSON.stringify(response))

                        this.errors = {
                            show: false,
                            message: '',

                            fields: {},
                        }

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message,
                            type: 'success',
                            delay: 5000,
                        })

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        reset() {
            this.errors = {
                show: false,
                message: '',

                fields: {},
            }

            if (!this.is_edit_mode) {
                this.original_asap_config = JSON.parse(JSON.stringify(EMPTY_ASAP_CONFIG))
            }

            this.asap_config = JSON.parse(JSON.stringify(this.original_asap_config))
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'vfenvironment_options',
        ]),

        env() {
            return this.$route.params.env
        },

        back_to() {
            return this.referrer && this.referrer.name == 'asap-configuration-list'
                ? this.referrer
                : { name: 'asap-configuration' }
        },

        asap_config_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_edit_mode() {
            return Boolean(this.asap_config_uuid)
        },

        is_changed() {
            let is_changed = false

            for (const key in EMPTY_ASAP_CONFIG) {
                is_changed = this.asap_config[key] != this.original_asap_config[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },

        exception_flag_options() {
            return [
                {
                    value: true,
                    label: 'Yes',
                },
                {
                    value: false,
                    label: 'No',
                },
            ]
        },

        outdial_prefix_options() {
            return [
                {
                    value: '55901139',
                    title: '55901139',
                },
                {
                    value: '55911300',
                    title: '55911300',
                },
                {
                    value: '55911301',
                    title: '55911301',
                },
            ]
        },

        service_node_options() {
            let options = []

            if (this.vfenvironment_options[this.env] in CONFIG) {
                options = CONFIG[this.vfenvironment_options[this.env]].SERVICE_NODE.map(node => ({
                    value: node,
                    title: node,
                }))
            }

            return options
        },

        sms_billing_id_options() {
            let options = []

            if (this.vfenvironment_options[this.env] in CONFIG) {
                options = CONFIG[this.vfenvironment_options[this.env]].SMS_BILLING_ID.map(id => ({
                    value: id,
                    title: id,
                }))
            }

            return options
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.is_edit_mode) {
                this.getASAPConfig()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-config-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 30px;
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-group {
            .form-controls {
                margin-bottom: 30px;

                .app-textarea {
                    height: 106px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:nth-child(5) {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .app-dropdown-select {
                        max-width: calc(50% - 15px);
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-config-details {
        .btn-back-to {
            margin-bottom: 16px;
        }

        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            .form-group {
                .form-controls {
                    margin-bottom: 24px;

                    .app-radio-buttons {
                        &.inline {
                            .radio-btns {
                                justify-content: flex-start;

                                .radio-button {
                                    margin-right: 16px;
                                }
                            }
                        }
                    }

                    &:nth-child(5) {
                        .app-dropdown-select {
                            max-width: 100%;

                            &:first-child {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>