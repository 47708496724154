<template>
    <div class="app-textarea"
        :class="{ typing, required, disabled, error }"
        @click="focus"
    >
        <div   v-if="error" class="error-message"><i></i><span>{{ error }}</span></div>
        <label v-else><span>{{ label }}<i v-if="required">*</i></span></label>

        <textarea
            :value="value"
            :disabled="disabled"
            :maxlength="maxlength"

            @input="onInput($event.target.value)"
            @focus="onFocus"
            @blur="onBlur"

            ref="textarea"
        ></textarea>

        <div class="characters-counter" v-if="charactersCounter && is_focused">
            <span>{{ characters_counter }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value:       { required: false },
        label:       { type: String, default: null },
        required:    { type: Boolean, default: false },
        disabled:    { type: Boolean, default: false },
        error:       { default: false },
        maxlength:   { default: null },

        charactersCounter: { type: Boolean, default: false },
    },

    data() {
        return {
            is_focused: false,
        }
    },

    computed: {
        typing() {
           return this.is_focused || (this.value || this.value === 0)
        },

        characters_counter() {
            const length = typeof this.value == 'string' ? this.value.length : 0

            const characters_counter = `${ length } / ${ this.maxlength }`

            return characters_counter
        },
    },

    methods: {
        onInput(value) {
            this.$emit('input', value)
            this.$emit('change', value)
        },

        onFocus() {
            this.is_focused = true
        },

        onBlur() {
            this.is_focused = false
        },

        focus() {
            this.$refs.textarea.focus()
        },
    }
}
</script>

<style lang="scss">
.app-textarea {
    position: relative;
    width: 100%;
    height: 197px;

    textarea {
        display: block;
        width: 100%;
        height: 100%;
        padding: 16px;
        color: var(--color-text-primary);
        border: 1px solid var(--color-input-border);
        border-radius: $border-radius-primary;
        background-color: var(--color-input-bg);
        resize: none;

        &:hover {
            border-color: var(--color-input-border-hover);
        }
    }

    label {
        position: absolute;
        top: 16px;
        left: calc(16px - 4px);
        padding: 0 4px;
        color: var(--color-input-label);
        background: var(--color-input-bg);
        transition: $transition-duration-primary;

        span {
            position: relative;
        }

        i {
            color: var(--color-error);
            font-style: normal;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--color-input-bg);
            content: '';
        }
    }

    .characters-counter {
        position: absolute;
        bottom: 0;
        right: 14px;

        max-width: calc(100% - 2 * 14px - 1px);

        padding: 0 4px;

        font-size: 14px;
        font-weight: normal;
        color: var(--color-input-label-typing);

        transform: translateY(50%);

        span {
            position: relative;

            display: block;

            @include text-overflow();
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--color-component-bg-primary);
        }
    }

    .error-message {
        position: absolute;
        bottom: 100%;
        left: 0;
        display: flex;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-error);

        i {
            display: inline-block;

            width: 24px;
            height: 24px;

            @include icon-before($icon-attention);
        }

        span {
            @include text-overflow();
        }
    }

    &.typing {
        label {
            top: 0;
            transform: translateY(-50%);
            color: var(--color-input-label-typing);

            i {
                color: inherit;
            }

            &::before {
                background: var(--color-component-bg-primary);
            }
        }

        textarea {
            border-color: var(--color-input-border-active);
        }
    }

    &.disabled {
        opacity: 0.4;
    }

    &.error {
        label {
            color: var(--color-error);
        }

        textarea {
            color: var(--color-error);
            border: 1px solid var(--color-input-border-error);
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-textarea {
        font-size: 14px;

        .characters-counter {
            font-size: 12px;
        }
    }
}
</style>