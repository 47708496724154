<template>
    <div class="app-radio-buttons" :class="{ inline, error }">
        <app-loader v-if="is_loading"></app-loader>

        <div class="label" v-if="label">{{ label }}</div>

        <div class="radio-btns">
            <template v-for="(item, key) in items">
                <input type="radio" :key="`item-${ item[keyValue] }`"
                    :id="id(item)"
                    
                    :name="_uid"
                    :value="item[keyValue]"
                    :checked="(value || value != undefined) && item[keyValue] === (returnItem ? value[keyValue] : value)"

                    :disabled="item.disabled"
                    
                    @click="handleChange(item)"
                >

                <label class="radio-button" :for="id(item)" :key="`label-${ key }`"
                    :class="{ disabled: item.disabled }"
                >
                    <div class="circle"></div>
                    <div class="label">{{ item[keyLabel] }}</div>
                </label>
            </template>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'

export default {
    props: {
        value:      { required: true },

        items:      { required: true, type: [ Array, Object ] },

        label:      { type: String  },
        required:   { type: Boolean },

        inline:     { type: Boolean },

        keyValue:   { type: String, default: 'value' },
        keyLabel:   { type: String, default: 'label' },

        returnItem: { type: Boolean, default: false  },

        loading:    { type: Boolean, default: false  },
        processing: { type: Boolean, default: false  },

        error:      { default: false },
    },

    components: {
        appLoader,
    },

    methods: {
        handleChange(item) {
            const value = this.returnItem ? item : item[this.keyValue]

            this.$emit('input', value)
            this.$emit('select', value)

            if (this.returnItem
                ? (!this.value || this.value[this.keyValue] !== value[this.keyValue])
                : (this.value !== value)
            ) {
                this.$emit('change', value)
            }
        },

        id(item) {
            return `${ item[this.keyValue] }-${this._uid}`
        }
    },

    computed: {
        is_loading() {
            return this.loading || this.processing
        },
    },
}
</script>

<style lang="scss">
.app-radio-buttons {
    position: relative;

    &>.label {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    input[type=radio] {
        display: none;

        &+label {
            display: flex;
            align-items: center;
            width: fit-content;
            cursor: pointer;

            &>.circle {
                position: relative;
                width: 18px;
                height: 18px;
                background-color: transparent;
                border: 2px solid var(--color-btn-primary-bg);
                border-radius: 50%;
                flex-shrink: 0;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                    height: 8px;
                    opacity: 0;
                    background: var(--color-btn-primary-bg);
                    border-radius: 50%;
                    transition: opacity $transition-duration-primary;
                }
            }

            &>.label {
                margin-left: 8px;
                font-size: 16px;
                line-height: 1.5;
                color: var(--color-radio-btns-label);
                user-select: none;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                &>.circle {
                    border-color: var(--color-btn-primary-bg-hover);

                    &:after {
                        background: var(--color-btn-primary-bg-hover);
                    }
                }
            }

            &.disabled {
                cursor: default;
                opacity: .4;
                pointer-events: none;

                &>.circle {
                    border-color: var(--color-btn-primary-bg);

                    &:after {
                        background: var(--color-btn-primary-bg);
                    }
                }
            }
        }

        &:checked {
            &+label {
                .circle {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.inline {
        .radio-btns {
            display: flex;

            .radio-button {
                margin-right: 48px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &.error {
        border-color: var(--color-error);
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-radio-buttons {
        min-height: 24px;
        
        input[type=radio] {
            &+label {
                &>.label {
                    font-size: 14px;
                    line-height: 16px;
                    margin-left: 4px;
                }
            }
        }

        &.inline {
            .radio-btns {
                justify-content: space-between;

                .radio-button {
                    margin-right: 4px;
                }
            }
        }
    }
}
</style>