import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    PhoneNumber: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
        custom_message: 'Please, enter valid phone number',
    },

    ProductCode: {
        name: 'Product code',
        trim: true,
        required: true,
    },

    ServiceNode: {
        name: 'Service node',
        required: true,
        format: /^\d{1,3}$/,
    },

    SMSBillingId: {
        name: 'SMS billing id',
        required: true,
        format: /^\d{1,3}$/,
    },

    OutdialPrefix: {
        name: 'Outdial prefix',
        required: true,
    },
})